.PassportModal {
    height: 35rem;
    width: 25rem;
    position: absolute;
    z-index: 10;
    border-radius: 1rem;
    border: solid lightgray 2px;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    background-color: white;
}

.innerModal {
    padding: 1rem;
    overflow: hidden;
}
